import { Link } from "react-router-dom";
import "../styles/button.scss";

const Button = ({ text, to = "/", bgColor, className }) => {
  return (
    <Link
      to={to}
      className={`btn ${className}`}
      style={{ backgroundColor: bgColor }}
    >
      {text}
    </Link>
  );
};

export default Button;
