import { useState } from "react";
import "../styles/questions.scss";

import GoodAnswer from "./GoodAnswer";
import Question from "./Question";

const questions = [
  { id: 1, text: "Quod iam tamen quos", isCorrect: false },
  { id: 2, text: "Quod iam tamen quos aut dumtaxat", isCorrect: true },
  { id: 3, text: "Quod iam tamen quos", isCorrect: false },
];

const Questions = () => {
  const [showMessage, setShowMessage] = useState(false);

  return (
    <div style={{ marginBlock: "2rem" }}>
      {questions.map(({ id, text, isCorrect }) => (
        <Question
          key={id}
          text={text}
          isCorrect={isCorrect}
          showMessage={showMessage}
          setShowMessage={setShowMessage}
        />
      ))}
      {showMessage && <GoodAnswer />}
      <div className="text-uppercase button">Suivant</div>
    </div>
  );
};

export default Questions;
