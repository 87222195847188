import "../styles/questionnaire.scss";
import { FiArrowLeft } from "react-icons/fi";

import AppLink from "../components/AppLink";
import NumbersList from "../components/NumbersList";
import Questions from "../components/Questions";

const Questionnaire = () => {
  return (
    <div className="questionnaire-container">
      <AppLink to="/" Icon={FiArrowLeft} text="Questionnaires" order={2} />
      <div className="numbers-container">
        <NumbersList />
      </div>
      <div className="questions-container">
        <Questions />
      </div>
    </div>
  );
};

export default Questionnaire;
