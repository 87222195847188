import "../styles/magasins.scss";
import { FiArrowRight } from "react-icons/fi";

import Button from "../components/Button";
import Title from "../components/Title";
import Slider from "../components/Slider";
import SliderScaling from "../components/SliderScaling";
import Marques from "../components/Marques";
import AppLink from "../components/AppLink";

const Magasins = () => {
  return (
    <div className="magasins-container">
      <Title text="The place to be" />
      <Slider />
      <Button text="Voir toutes les actualités" to="/" />
      <Title text="événements tendance" />
      <SliderScaling />
      <Title text="nos marques" />
      <Marques />
      <AppLink
        to="/magasins"
        text="Découvrir tous les magasins"
        Icon={FiArrowRight}
      />
    </div>
  );
};

export default Magasins;
