import { Link } from "react-router-dom";
import "../styles/applink.scss";

const AppLink = ({ to, text, Icon, order }) => {
  return (
    <Link to={to} className="link">
      <span style={{ order }}>{text}</span>
      <Icon />
    </Link>
  );
};

export default AppLink;
