import "../styles/navbar.scss";
import { FaEllipsisV, FaSearch, FaQrcode } from "react-icons/fa";

const Navbar = () => {
  return (
    <header className="navbar-container">
      <div className="icon-container">
        <FaEllipsisV />
      </div>
      <h3 className="logo text-uppercase">Logo</h3>
      <div className="right">
        <div className="icon-container">
          <FaSearch className="search-icon" />
        </div>
        <div className="icon-container">
          <FaQrcode />
        </div>
      </div>
    </header>
  );
};

export default Navbar;
