import { useEffect, useState } from "react";
import "../styles/marques.scss";

const images = [
  {
    imageSrc: require("../assets/imgs/marques/GO_Sport_logo.jpg"),
  },
  {
    imageSrc: require("../assets/imgs/marques/Image 24.jpg"),
  },
  {
    imageSrc: require("../assets/imgs/marques/Image 25.jpg"),
  },
  {
    imageSrc: require("../assets/imgs/marques/Image 27.jpg"),
  },
  {
    imageSrc: require("../assets/imgs/marques/Logo-beauty-succes.jpg"),
  },
  {
    imageSrc: require("../assets/imgs/marques/swatch-logo.jpg"),
  },
  {
    imageSrc: require("../assets/imgs/marques/Image 25.jpg"),
  },
  {
    imageSrc: require("../assets/imgs/marques/GO_Sport_logo.jpg"),
  },
  {
    imageSrc: require("../assets/imgs/marques/Logo-beauty-succes.jpg"),
  },
];

const Marques = ({ displayLines = true }) => {
  const [index, setIndex] = useState(0);
  const [state, setState] = useState([]);
  const [countes, setCountes] = useState();
  const [lines, setLines] = useState([]);

  useEffect(() => {
    setCountes(Math.ceil(images.length / 6));
  }, []);

  useEffect(() => {
    let numbers = [];
    for (let i = 0; i < countes; i++) {
      numbers.push(i);
    }
    setLines(numbers);
  }, [countes]);

  useEffect(() => {
    const res = images.slice(index * 6, (index + 1) * 6);
    if (res.length > 0) setState(res);
    else setIndex(0);
  }, [index]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex(index + 1);
    }, 5000);
    return () => clearTimeout(timer);
  }, [index]);

  const classNameGenerator = (i) => {
    if (i < index) return "bg-white";
    if (i === index) return "animate-bg";
  };

  return (
    <div className="marques-container">
      <div className="imgs-container">
        {state.map((image, i) => (
          <div key={i} className="img-container">
            <img src={image.imageSrc} alt="" />
          </div>
        ))}
      </div>
      {displayLines && (
        <div className="marques-container__duration">
          {lines.map((l) => (
            <div className={classNameGenerator(l)} key={l}>
              {l === index && <div></div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Marques;
