import { useState, useEffect } from "react";

import "../styles/sliderScaling.scss";

const images = [
  {
    id: 1,
    imageSrc: require("../assets/imgs/Mask Group 6.jpg"),
    title: "Lorem ipsum",
    date_range: "20 Oct - 25 Nov",
  },
  {
    id: 2,
    imageSrc: require("../assets/imgs/Mask Group 13.jpg"),
    title: "Lorem ipsum",
    date_range: "20 Oct - 25 Nov",
  },
  {
    id: 3,
    imageSrc: require("../assets/imgs/p2.jpg"),
    title: "Lorem ipsum",
    date_range: "20 Oct - 25 Nov",
  },
];

const SliderScaling = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);
    return () => clearTimeout(timer);
  }, [index]);

  const generateClassName = (id) => {
    const indexOfImage = images.findIndex((img) => img.id === id);

    if (indexOfImage === images.length - 1) return "last";

    return "others";
  };

  return (
    <div className="slider-scaling-container">
      {images.map((image) => (
        <div
          key={image.id}
          className={`slider-wrapper ${generateClassName(image.id)}`}
          style={{
            backgroundImage: `url('${image.imageSrc}')`,
          }}
        >
          <div className="slider-content">
            <h2 className="text-uppercase">{image.title}</h2>
            <p>{image.date_range}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SliderScaling;
