import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";

import Navbar from "./components/Navbar";
import Magasins from "./pages/Magasins";
import Questionnaire from "./pages/Questionnaire";
import Restaurants from "./pages/Restaurants";

import {
  magasins_route,
  questions_route,
  restaurants_route,
} from "./constants/routes";
import Layout from "./components/Layout";

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path={magasins_route} element={<Magasins />} />
          <Route path={restaurants_route} element={<Restaurants />} />
        </Route>
        <Route path={questions_route} element={<Questionnaire />} />
      </Routes>
    </Router>
  );
};

export default App;
