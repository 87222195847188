import "../styles/question.scss";
import { useState } from "react";

import { FcCheckmark } from "react-icons/fc";

const Question = ({ text, isCorrect, setShowMessage, showMessage }) => {
  const [correct, setCorrect] = useState(false);

  const handleClick = () => {
    setCorrect(isCorrect);
    setShowMessage(isCorrect);
  };

  return (
    <div className={`question-container ${correct && "correct"}`}>
      {correct ? (
        <FcCheckmark />
      ) : (
        <input type="checkbox" disabled={showMessage} onClick={handleClick} />
      )}
      <label className="label">
        <span>{text}</span>
      </label>
    </div>
  );
};

export default Question;
