import "../styles/slider.scss";
import { useEffect, useState } from "react";

const images = [
  {
    imageSrc: require("../assets/imgs/Mask Group 13.jpg"),
    title: "Anfaplace mall",
    subtitle: "www.anfaplacemall.ma",
  },
  {
    imageSrc: require("../assets/imgs/p1.jpg"),
    title: "This is the second title",
    subtitle: "This is the second subtitle",
  },
  {
    imageSrc: require("../assets/imgs/p2.jpg"),
    title: "This is the third title",
    subtitle: "This is the third subtitle",
  },
];

const Slider = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (index === images.length - 1) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [index]);

  const classNameGenerator = (i) => {
    if (i < index) return "bg-white";
    if (i === index) return "animate-bg";
  };

  return (
    <div className="images-container">
      <img src={images[index].imageSrc} alt="" />
      <div className="info-container">
        <h2 className="info-container__title text-uppercase">
          {images[index].title}
        </h2>
        <p className="info-container__subtitle">{images[index].subtitle}</p>
        <div className="info-container__duration">
          {images.map((img, i) => (
            <div className={classNameGenerator(i)} key={i}>
              {i === index && <div></div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slider;
