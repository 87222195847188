import "../styles/navigation.scss";
import { NavLink } from "react-router-dom";

import { FaShoppingBag } from "react-icons/fa";
import { IoMdRestaurant, IoMdInformationCircleOutline } from "react-icons/io";

import { magasins_route, restaurants_route } from "../constants/routes";

const Navbarlink = ({ to, Icon, text }) => {
  return (
    <NavLink
      className={(navdata) => (navdata.isActive ? "navlink active" : "navlink")}
      to={to}
    >
      <Icon />
      <span className="navlink__text">{text}</span>
    </NavLink>
  );
};

const Navigation = () => {
  return (
    <nav className="navigation-container">
      <Navbarlink to={magasins_route} Icon={FaShoppingBag} text="Magasins" />
      <Navbarlink
        to={restaurants_route}
        Icon={IoMdRestaurant}
        text="Restaurants"
      />
      <Navbarlink
        to="/questionnaires"
        Icon={IoMdInformationCircleOutline}
        text="Informations"
      />
    </nav>
  );
};

export default Navigation;
