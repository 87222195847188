import "../styles/number.scss";

const Number = ({ text, index, i }) => {
  const classNameGenerator = (i) => {
    if (i < index) return "bg-white";
    if (i === index) return "animate-bg";
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "1rem",
      }}
    >
      <p className="number">{text}</p>
      <div className={`${classNameGenerator(i)} line`}>
        {i === index && <div></div>}
      </div>
    </div>
  );
};

export default Number;
