import "../styles/goodanswer.scss";

const GoodAnswer = () => {
  return (
    <div className="goodanswer-container">
      <h4>Bonne réponse</h4>
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
      </p>
    </div>
  );
};

export default GoodAnswer;
