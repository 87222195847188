import { useState, useEffect } from "react";
import Number from "./Number";

const NumbersList = () => {
  const [questionsGroups, setQuestionsGroups] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setQuestionsGroups([0, 1, 2]);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (index === questionsGroups.length - 1) setIndex(0);
      else setIndex(index + 1);
    }, 2000);

    return () => clearTimeout(timer);
  }, [index]);

  return (
    <div className="number-container">
      <div className="number-container__duration">
        {questionsGroups.map((q) => (
          <Number key={q} text={q + 1} index={index} i={q} />
        ))}
      </div>
    </div>
  );
};

export default NumbersList;
